<template>
  <div class="affixion-contain">
    <Form :label-width="115">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="握力左：">
            <Row>
              <Col :span="22"><Input v-model="gripData.left_grip" placeholder="请输入握力左"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;kg</Col>
            </Row>
          </Form-item>
          <Form-item label="握力右：">
            <Row>
              <Col :span="22"><Input v-model="gripData.right_grip" placeholder="请输入握力右"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;kg</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			gripData: {
				test_date: '',
				test_time: '',
				left_grip: '',
				right_grip: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.gripData.member_id = this.memberId;
				MemberService.gripAdd(this.gripData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.gripData.test_date = value;
		},
		handleChangeTime(value) {
			this.gripData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.gripData.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.gripData.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else if (this.gripData.left_grip === '') {
				this.$Message.warning('请输入握力左');
				return false;
			} else if (!this.isPositiveNumber(this.gripData.left_grip)) {
				this.$Message.warning('请输入握力左有效值');
				return false;
			} else if (this.gripData.right_grip === '') {
				this.$Message.warning('请输入握力右');
				return false;
			} else if (!this.isPositiveNumber(this.gripData.right_grip)) {
				this.$Message.warning('请输入握力右有效值');
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
